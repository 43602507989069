/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const EventCard = ({ data }) => {
  const urlPath = `/${data.frontmatter.path}${data.frontmatter.slug}`
  return (
    <article
      className="post-card"
      sx={{
        bg: "cardBg",
      }}
    >
      {data.frontmatter.featuredImage ? (
        <Link to={urlPath}>
          <GatsbyImage
            image={data.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
            alt={data.frontmatter.title + " - Featured image"}
            className="featured-image"
          />
        </Link>
      ) : (
        ""
      )}
      <div className="post-content">
        <h2 className="title">
          <Link className="no-decor"
            to={urlPath}
            sx={{
              variant: "links.postLink",
            }}
          >
            {data.frontmatter.title}
          </Link>
        </h2>
        <p
          className="meta"
          sx={{
            color: "muted",
          }}
        >
          <time>{data.frontmatter.date}</time>
        </p>
      </div>
    </article>
  )
}

export default EventCard
